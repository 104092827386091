import {
  Alert,
  AlertTitle,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import validator from "validator";

function HealthControls(props) {
  const [damage, setDamage] = useState();
  const [repair, setRepair] = useState();
  const [aoeDamage, setAoeDamage] = useState();
  const [error, setError] = useState();
  const [errorText, setErrorText] = useState();

  useEffect(() => {
    setDamage(0);
    setRepair(0);
    setAoeDamage(0);
    setError(false);
    setErrorText("");
  }, [props]);

  function handleAoeDamageInput(event) {
    if (event.currentTarget.value !== null) {
      if (
        validator.isInt(event.currentTarget.value) ||
        event.currentTarget.value === ""
      ) {
        setAoeDamage(event.currentTarget.value);
        setError(false);
        setErrorText("");
      } else {
        setError(true);
        setErrorText("Must be an integer");
      }
    }
  }

  function handleDamageInput(event) {
    if (event.currentTarget.value !== null) {
      if (
        validator.isInt(event.currentTarget.value) ||
        event.currentTarget.value === ""
      ) {
        setDamage(event.currentTarget.value);
        setError(false);
        setErrorText("");
      } else {
        setError(true);
        setErrorText("Must be an integer");
      }
    }
  }

  function handleRepairInput(event) {
    if (event.currentTarget.value !== null) {
      if (
        validator.isInt(event.currentTarget.value) ||
        event.currentTarget.value === ""
      ) {
        setRepair(event.currentTarget.value);
        setError(false);
        setErrorText("");
      } else {
        setError(true);
        setErrorText("Must be an integer");
      }
    }
  }

  function handleLaserDamage(event) {
    if (props.maxHp * 0.05 <= 80) {
      props.callback(props.system, aoeDamage, props.maxHp * 0.05, repair);
    } else {
      props.callback(props.system, aoeDamage, 80, repair);
    }
  }

  function handleHpChange(event) {
    props.callback(props.system, aoeDamage, damage, repair);
  }

  return (
    <Card elevation={6} sx={{ marginBottom: "30px", marginRight: "2%" }}>
      <CardHeader title={props.system} />
      {error && (
        <Alert severity="error">
          <AlertTitle>Error</AlertTitle>
          {errorText}
        </Alert>
      )}
      <Typography variant="h4">
        {props.currentHp}/{props.maxHp}
      </Typography>
      {props.system === "Base Hull" && (
        <Button size="small" variant="contained" onClick={handleLaserDamage}>
          Hull Laser Hit
        </Button>
      )}
      <CardContent
        sx={{
          height: "fit-content",
          minHeight: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        {props.system === "Base Hull" && (
          <TextField
            label="AOE Damage"
            variant="outlined"
            value={aoeDamage || ""}
            onChange={handleAoeDamageInput}
          />
        )}
        <TextField
          label="Targeted Damage"
          variant="outlined"
          value={damage || ""}
          onChange={handleDamageInput}
        />
        <TextField
          label="Repairs"
          variant="outlined"
          value={repair || ""}
          onChange={handleRepairInput}
        />
        <CardActions>
          <Button variant="contained" onClick={handleHpChange}>
            Confirm
          </Button>
        </CardActions>
      </CardContent>
    </Card>
  );
}

export default HealthControls;
