import { Button } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import HealthTracker from "../HealthTracker/HealthTracker";
import WeaponCalculators from "../WeaponCalculators/WeaponCalculators";

function Sheet(props) {
  let navigate = useNavigate();

  useEffect(() => {
    if (
      props.counts.length === null ||
      props.ship.length === null ||
      props.weaponSystems.length === null
    ) {
      navigate(`/`, { replace: true });
    }
  });

  function handleToBuilder() {
    navigate(`/`, { replace: true });
  }

  const handleSave = async () => {
    const fileName = "ship";
    const json = JSON.stringify(props);
    const blob = new Blob([json]);
    const href = await URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Button
        variant="contained"
        size="small"
        onClick={handleToBuilder}
        sx={{ zIndex: "2", position: "fixed", top: "10px", left: "10px" }}
      >
        To Builder
      </Button>
      <HealthTracker
        counts={props.counts}
        shipCategory={props.ship.shipCategory}
        shipType={props.ship.shipType}
      />
      <WeaponCalculators weaponSystems={props.weaponSystems} />
      <Button
        variant="contained"
        onClick={handleSave}
        sx={{ marginBottom: "50px" }}
      >
        Save Ship
      </Button>
    </>
  );
}

export default Sheet;
