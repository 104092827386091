import { Add, Remove } from "@mui/icons-material";
import {
  Card,
  CardContent,
  CardHeader,
  FormControl,
  FormControlLabel,
  FormLabel,
  Paper,
  Radio,
  RadioGroup,
  Typography,
  Chip,
  Fab,
  Stack,
  styled,
  Divider,
  Alert,
  AlertTitle,
  LinearProgress,
  CardActionArea,
  Button,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Builder.css";

function Builder(props) {
  const [value, setValue] = useState("fighter");
  const [ship, setShip] = useState([]);
  const [items, setItems] = useState([]);
  const [counts, setCounts] = useState([]);
  const [weaponSystems, setWeaponSystems] = useState([]);
  const [slots, setSlots] = useState(0);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  let navigate = useNavigate();

  const Subsystem = styled(Card)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
  }));

  useEffect(() => {
    setShip({
      shipType: "fighter",
      slots: 14,
      shipCategory: 1,
    });

    setItems([]);
    setWeaponSystems([]);
    setSlots(0);
    setError(false);
    setErrorMessage("");

    setCounts({
      engines: 0,
      shields: 0,
      hulls: 0,
      sensors: 0,
      quarters: 0,
      cargos: 0,
      cloaks: 0,
      techs: 0,
      biotanks: 0,
      haulers: 0,
      hangers: 0,
      weapons: 0,
    });
  }, []);

  function handleChangeType(event) {
    let selected = event.currentTarget.value;
    setValue(event.currentTarget.value);
    if (selected === "fighter") {
      setShip({
        shipType: selected,
        slots: 14,
        shipCategory: 1,
      });
    } else if (selected === "gunship") {
      setShip({
        shipType: selected,
        slots: 26,
        shipCategory: 1,
      });
    } else if (selected === "corvette") {
      setShip({
        shipType: selected,
        slots: 51,
        shipCategory: 2,
      });
    } else if (selected === "frigate") {
      setShip({
        shipType: selected,
        slots: 75,
        shipCategory: 2,
      });
    } else if (selected === "cruiser") {
      setShip({
        shipType: selected,
        slots: 101,
        shipCategory: 3,
      });
    } else if (selected === "battleship") {
      setShip({
        shipType: selected,
        slots: 125,
        shipCategory: 3,
      });
    }

    setItems([]);
    setWeaponSystems([]);
    setSlots(0);
    setError(false);
    setErrorMessage("");

    setCounts({
      engines: 0,
      shields: 0,
      hulls: 0,
      sensors: 0,
      quarters: 0,
      cargos: 0,
      cloaks: 0,
      techs: 0,
      biotanks: 0,
      haulers: 0,
      hangers: 0,
      weapons: 0,
    });
  }

  const handleDeleteItem = (itemToDelete) => () => {
    setItems((items) => items.filter((item) => item.key !== itemToDelete.key));
    setCounts((counts) => ({
      ...counts,
      [itemToDelete.label]: getCurrent(itemToDelete.label) - 1,
    }));
    setSlots((slots) => slots - getCost(itemToDelete.label));
    let weaponToRemove = weaponSystems.pop();
    setWeaponSystems((weaponSystems) =>
      weaponSystems.filter((weapon) => weapon !== weaponToRemove)
    );
  };

  function getCurrent(name) {
    switch (name) {
      case "engines":
        return counts.engines;
      case "shields":
        return counts.shields;
      case "hulls":
        return counts.hulls;
      case "sensors":
        return counts.sensors;
      case "quarters":
        return counts.quarters;
      case "cargos":
        return counts.cargos;
      case "cloaks":
        return counts.cloaks;
      case "techs":
        return counts.techs;
      case "biotanks":
        return counts.biotanks;
      case "hangers":
        return counts.hangers;
      case "haulers":
        return counts.haulers;
      case "weapons":
        return counts.weapons;
      default:
        return 0;
    }
  }

  function getCost(name) {
    let cost = ship.shipCategory;
    if (ship.shipCategory === 3) {
      cost = 4;
    }
    switch (name) {
      case "engines":
        return cost;
      case "shields":
        return cost;
      case "hulls":
        return cost;
      case "sensors":
        return 1;
      case "quarters":
        return 4;
      case "cargos":
        return 4;
      case "cloaks":
        return 4;
      case "techs":
        return 4;
      case "biotanks":
        return 2;
      case "hangers":
        return 4;
      case "haulers":
        return 2;
      case "weapons":
        return 4;
      default:
        return 0;
    }
  }

  function checkError(name, current, cost) {
    if (
      (name === "haulers" || name === "cloaks" || name === "sensors") &&
      current === 1
    ) {
      setError(true);
      setErrorMessage("You can only have one " + name + ".");
      return false;
    } else if (slots + cost > ship.slots) {
      setError(true);
      setErrorMessage("You have no more slots on your ship.");
      return false;
    } else if (name === "hulls" && current === 4 * ship.shipCategory) {
      setError(true);
      setErrorMessage(
        "You can only have " + 4 * ship.shipCategory + " hull platings."
      );
      return false;
    }
    return true;
  }

  function handleAdd(event) {
    let name = event.currentTarget.name;
    let current = getCurrent(name);
    let cost = getCost(name);

    if (checkError(name, current, cost)) {
      setCounts((counts) => ({
        ...counts,
        [event.currentTarget.name]: current + 1,
      }));
      setItems((items) => items.concat({ key: items.length, label: name }));
      setSlots((slots) => slots + cost);
    }
  }

  function handleRemove(event) {
    let name = event.currentTarget.name;
    let current = getCurrent(name);
    let cost = getCost(name);

    if (current !== 0 && items.length > 0 && slots >= cost) {
      setCounts((counts) => ({
        ...counts,
        [name]: current - 1,
      }));

      let lastItemAdded = items.filter((item) => item.label === name).pop().key;

      setItems((items) =>
        items
          .filter((item) => item.label !== name)
          .concat(
            items.filter(
              (item) => item.label === name && item.key !== lastItemAdded
            )
          )
      );

      setSlots((slots) => slots - cost);
    }
  }

  function handleAddWeaponSystem(event) {
    let name = event.currentTarget.name;
    let current = getCurrent(name);
    let cost = getCost(name);

    if (checkError(name, current, cost)) {
      setCounts((counts) => ({
        ...counts,
        [event.currentTarget.name]: current + 1,
      }));
      setItems((items) => items.concat({ key: items.length, label: name }));
      setSlots((slots) => slots + cost);
      setWeaponSystems((weaponSystems) =>
        weaponSystems.concat({
          key: weaponSystems.length,
          autoCannon: 0,
          ionDisabler: 0,
          hullLaser: 0,
          flakCannon: 0,
          mineLayer: 0,
          energyCannon: 0,
          torpedoLauncher: 0,
          interstellarArtillery: 0,
          railgun: 0,
          gravitationalVice: 0,
        })
      );
    }
  }

  function handleRemoveWeaponSystem(event) {
    let name = event.currentTarget.name;
    let current = getCurrent(name);
    let cost = getCost(name);

    if (current !== 0 && items.length > 0 && slots >= cost) {
      setCounts((counts) => ({
        ...counts,
        [name]: current - 1,
      }));

      let lastItemAdded = items.filter((item) => item.label === name).pop().key;

      setItems((items) =>
        items
          .filter((item) => item.label !== name)
          .concat(
            items.filter(
              (item) => item.label === name && item.key !== lastItemAdded
            )
          )
      );

      setSlots((slots) => slots - cost);

      let lastWeapon = weaponSystems.pop();
      setWeaponSystems((weaponSystems) =>
        weaponSystems.filter((weapon) => weapon.key !== lastWeapon.key)
      );
    }
  }

  function getWeaponCounts(weaponName, key) {
    let currentWeapons = weaponSystems
      .filter((weaponSystem) => weaponSystem.key === key)
      .pop();
    switch (weaponName) {
      case "autoCannon":
        return currentWeapons.autoCannon;
      case "ionDisabler":
        return currentWeapons.ionDisabler;
      case "hullLaser":
        return currentWeapons.hullLaser;
      case "flakCannon":
        return currentWeapons.flakCannon;
      case "mineLayer":
        return currentWeapons.mineLayer;
      case "energyCannon":
        return currentWeapons.energyCannon;
      case "torpedoLauncher":
        return currentWeapons.torpedoLauncher;
      case "interstellarArtillery":
        return currentWeapons.interstellarArtillery;
      case "railgun":
        return currentWeapons.railgun;
      case "gravitationalVice":
        return currentWeapons.gravitationalVice;
      default:
        return 0;
    }
  }

  function getWeaponCosts(weaponName) {
    switch (weaponName) {
      case "autoCannon":
        return 1;
      case "ionDisabler":
        return 2;
      case "hullLaser":
        return 2;
      case "flakCannon":
        return 2;
      case "mineLayer":
        return 4;
      case "energyCannon":
        return 1;
      case "torpedoLauncher":
        return 4;
      case "interstellarArtillery":
        return 4;
      case "railgun":
        return 2;
      case "gravitationalVice":
        return 2;
      default:
        return 0;
    }
  }

  function getWeaponSlots(key) {
    return (
      getWeaponCounts("autoCannon", key) * getWeaponCosts("autoCannon") +
      getWeaponCounts("ionDisabler", key) * getWeaponCosts("ionDisabler") +
      getWeaponCounts("hullLaser", key) * getWeaponCosts("hullLaser") +
      getWeaponCounts("flakCannon", key) * getWeaponCosts("flakCannon") +
      getWeaponCounts("mineLayer", key) * getWeaponCosts("mineLayer") +
      getWeaponCounts("energyCannon", key) * getWeaponCosts("energyCannon") +
      getWeaponCounts("torpedoLauncher", key) *
        getWeaponCosts("torpedoLauncher") +
      getWeaponCounts("interstellarArtillery", key) *
        getWeaponCosts("interstellarArtillery") +
      getWeaponCounts("railgun", key) * getWeaponCosts("railgun") +
      getWeaponCounts("gravitationalVice", key) *
        getWeaponCosts("gravitationalVice")
    );
  }

  const handleAddWeapon = (weaponName, key) => () => {
    let weaponCount = getWeaponCounts(weaponName, key);
    let weaponSlots = getWeaponSlots(key);
    let weaponCost = getWeaponCosts(weaponName);
    if (weaponSlots + weaponCost <= 4) {
      setWeaponSystems((weaponSystems) =>
        weaponSystems.map((weapon) =>
          weapon.key === key
            ? {
                ...weapon,
                [weaponName]: weaponCount + 1,
              }
            : { ...weapon }
        )
      );
    }
  };

  const handleRemoveWeapon = (weaponName, key) => () => {
    let weaponCount = getWeaponCounts(weaponName, key);
    let weaponSlots = getWeaponSlots(key);
    let weaponCost = getWeaponCosts(weaponName);
    if (weaponSlots - weaponCost >= 0 && weaponCount > 0) {
      setWeaponSystems((weaponSystems) =>
        weaponSystems.map((weapon) =>
          weapon.key === key
            ? {
                ...weapon,
                [weaponName]: weaponCount - 1,
              }
            : { ...weapon }
        )
      );
    }
  };

  //Allows a user to upload a JSON file to
  function onFileChange(event) {
    const fileReader = new FileReader();
    fileReader.readAsText(event.currentTarget.files[0], "UTF-8");
    fileReader.onload = (event) => {
      try {
        let loadedShip = JSON.parse(event.target.result);
        if (
          loadedShip.ship.shipType !== null &&
          loadedShip.counts.engines > 0
        ) {
          props.callback(
            loadedShip.ship,
            loadedShip.counts,
            loadedShip.weaponSystems
          );
          navigate(`/sheet`, { replace: true });
        } else {
          setError(true);
          setErrorMessage("File was not validly formatted");
        }
      } catch (error) {
        setError(true);
        setErrorMessage("File was not validly formatted");
      }
    };
  }

  //Moves the user to the Sheet view
  function submitShip() {
    if (getCurrent("engines") >= 1) {
      props.callback(ship, counts, weaponSystems);
      navigate(`/sheet`, { replace: true });
    } else {
      setError(true);
      setErrorMessage("You must have atleast 1 engine.");
    }
  }

  function renderItems() {
    return items.map((item) => {
      return (
        <Chip
          sx={{ margin: "10px" }}
          label={item.label}
          key={item.key}
          onDelete={handleDeleteItem(item)}
        />
      );
    });
  }

  function renderWeaponSystems() {
    return weaponSystems.map((weapon) => {
      return (
        <Card key={weapon.key} sx={{ width: "100%", marginBottom: "10px" }}>
          <CardHeader title={"Weapon System"} />
          <CardContent>
            <Typography variant="h5">{getWeaponSlots(weapon.key)}/4</Typography>
            <LinearProgress
              variant="determinate"
              sx={{ marginBottom: "20px" }}
              value={(getWeaponSlots(weapon.key) / 4) * 100}
            />
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" />}
              sx={{ flexWrap: "wrap" }}
            >
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Auto Cannon</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="autoCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("autoCannon", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("autoCannon", weapon.key)}
                  </Typography>
                  <Fab
                    name="autoCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("autoCannon", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 1</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Energy Cannon</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="energyCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("energyCannon", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("energyCannon", weapon.key)}
                  </Typography>
                  <Fab
                    name="energyCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("energyCannon", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 1</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Ion Disabler</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="ionDisabler"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("ionDisabler", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("ionDisabler", weapon.key)}
                  </Typography>
                  <Fab
                    name="ionDisabler"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("ionDisabler", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 2</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Hull Laser</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="hullLaser"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("hullLaser", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("hullLaser", weapon.key)}
                  </Typography>
                  <Fab
                    name="hullLaser"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("hullLaser", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 2</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Flak Cannon</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="flakCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("flakCannon", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("flakCannon", weapon.key)}
                  </Typography>
                  <Fab
                    name="flakCannon"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("flakCannon", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 2</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Railgun</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="railgun"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("railgun", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("railgun", weapon.key)}
                  </Typography>
                  <Fab
                    name="railgun"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("railgun", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 2</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Gravitational Vice</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="gravitationalVice"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("gravitationalVice", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("gravitationalVice", weapon.key)}
                  </Typography>
                  <Fab
                    name="gravitationalVice"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon(
                      "gravitationalVice",
                      weapon.key
                    )}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 2</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Mine Layer</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="mineLayer"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("mineLayer", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("mineLayer", weapon.key)}
                  </Typography>
                  <Fab
                    name="mineLayer"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("mineLayer", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 4</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Torpedo Launcher</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="torpedoLauncher"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon("torpedoLauncher", weapon.key)}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("torpedoLauncher", weapon.key)}
                  </Typography>
                  <Fab
                    name="torpedoLauncher"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon("torpedoLauncher", weapon.key)}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 4</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Interstellar Artillery</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="interstellarArtillery"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeapon(
                      "interstellarArtillery",
                      weapon.key
                    )}
                  >
                    <Add />
                  </Fab>
                  <Typography>
                    {getWeaponCounts("interstellarArtillery", weapon.key)}
                  </Typography>
                  <Fab
                    name="interstellarArtillery"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeapon(
                      "interstellarArtillery",
                      weapon.key
                    )}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: 4</Typography>
              </Subsystem>
            </Stack>
          </CardContent>
        </Card>
      );
    });
  }

  return (
    <Paper
      sx={{
        marginTop: "2.5%",
        width: "80%",
        height: "fit-content",
        minHeight: "90%",
        backgroundColor: "#dcdcdc",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "5%",
      }}
    >
      <Typography variant="h3">Ship Builder</Typography>
      <Card sx={{ width: "80%", height: "fit-content", marginBottom: "10px" }}>
        <CardContent>
          <FormControl>
            <FormLabel id="ship-type-label">Ship Type</FormLabel>
            <RadioGroup
              aria-labelledby="ship-type-label"
              name="ship-type-group"
              value={value}
              onChange={handleChangeType}
            >
              <FormControlLabel
                value="fighter"
                control={<Radio />}
                label="Fighter"
              />
              <FormControlLabel
                value="gunship"
                control={<Radio />}
                label="Gunship"
              />
              <FormControlLabel
                value="corvette"
                control={<Radio />}
                label="Corvette"
              />
              <FormControlLabel
                value="frigate"
                control={<Radio />}
                label="Frigate"
              />
              <FormControlLabel
                value="cruiser"
                control={<Radio />}
                label="Cruiser"
              />
              <FormControlLabel
                value="battleship"
                control={<Radio />}
                label="Battleship"
              />
            </RadioGroup>
          </FormControl>
        </CardContent>
      </Card>
      <div className="subsystem-div">
        <Card sx={{ width: "100%", marginBottom: "10px" }}>
          <CardHeader title="Subsystems" />
          <CardContent sx={{ textAlign: "center" }}>
            <Typography variant="h5">
              {slots}/{ship.slots}
            </Typography>
            <LinearProgress
              variant="determinate"
              sx={{ marginBottom: "20px" }}
              value={(slots / ship.slots) * 100}
            />
            {error && (
              <Alert severity="error">
                <AlertTitle>Error</AlertTitle>
                {errorMessage}
              </Alert>
            )}
            <Typography>Selected Items:</Typography>
            {renderItems()}
            <Stack
              direction="row"
              divider={<Divider orientation="vertical" />}
              sx={{ flexWrap: "wrap" }}
            >
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Engine Room</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="engines"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.engines}</Typography>
                  <Fab
                    name="engines"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("engines")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Shield Generators</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="shields"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.shields}</Typography>
                  <Fab
                    name="shields"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("shields")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Hull Plating</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="hulls"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.hulls}</Typography>
                  <Fab
                    name="hulls"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("hulls")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Cargo Holds</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="cargos"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.cargos}</Typography>
                  <Fab
                    name="cargos"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("cargos")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Sensor</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="sensors"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.sensors}</Typography>
                  <Fab
                    name="sensors"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("sensors")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Cloaking Arrays</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="cloaks"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.cloaks}</Typography>
                  <Fab
                    name="cloaks"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("cloaks")}</Typography>
              </Subsystem>
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Hauler</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="haulers"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAdd}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.haulers}</Typography>
                  <Fab
                    name="haulers"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemove}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("haulers")}</Typography>
              </Subsystem>
              {ship.shipType !== "fighter" && (
                <>
                  <Subsystem
                    elevation={6}
                    sx={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    <CardActionArea>
                      <Typography>Tech Rooms</Typography>
                    </CardActionArea>
                    <div className="subsystem-controls">
                      <Fab
                        name="techs"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleAdd}
                      >
                        <Add />
                      </Fab>
                      <Typography>{counts.techs}</Typography>
                      <Fab
                        name="techs"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleRemove}
                      >
                        <Remove />
                      </Fab>
                    </div>
                    <Typography>Cost: {getCost("techs")}</Typography>
                  </Subsystem>
                  <Subsystem
                    elevation={6}
                    sx={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    <CardActionArea>
                      <Typography>Quarters</Typography>
                    </CardActionArea>
                    <div className="subsystem-controls">
                      <Fab
                        name="quarters"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleAdd}
                      >
                        <Add />
                      </Fab>
                      <Typography>{counts.quarters}</Typography>
                      <Fab
                        name="quarters"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleRemove}
                      >
                        <Remove />
                      </Fab>
                    </div>
                    <Typography>Cost: {getCost("quarters")}</Typography>
                  </Subsystem>
                  <Subsystem
                    elevation={6}
                    sx={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    <CardActionArea>
                      <Typography>Hangers</Typography>
                    </CardActionArea>
                    <div className="subsystem-controls">
                      <Fab
                        name="hangers"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleAdd}
                      >
                        <Add />
                      </Fab>
                      <Typography>{counts.hangers}</Typography>
                      <Fab
                        name="hangers"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleRemove}
                      >
                        <Remove />
                      </Fab>
                    </div>
                    <Typography>Cost: {getCost("hangers")}</Typography>
                  </Subsystem>
                  <Subsystem
                    elevation={6}
                    sx={{ marginBottom: "10px", marginLeft: "10px" }}
                  >
                    <CardActionArea>
                      <Typography>Biotanks</Typography>
                    </CardActionArea>
                    <div className="subsystem-controls">
                      <Fab
                        name="biotanks"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleAdd}
                      >
                        <Add />
                      </Fab>
                      <Typography>{counts.biotanks}</Typography>
                      <Fab
                        name="biotanks"
                        color="primary"
                        size="small"
                        sx={{ margin: "5px" }}
                        onClick={handleRemove}
                      >
                        <Remove />
                      </Fab>
                    </div>
                    <Typography>Cost: {getCost("biotanks")}</Typography>
                  </Subsystem>
                </>
              )}
              <Subsystem
                elevation={6}
                sx={{ marginBottom: "10px", marginLeft: "10px" }}
              >
                <CardActionArea>
                  <Typography>Weapon Systems</Typography>
                </CardActionArea>
                <div className="subsystem-controls">
                  <Fab
                    name="weapons"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleAddWeaponSystem}
                  >
                    <Add />
                  </Fab>
                  <Typography>{counts.weapons}</Typography>
                  <Fab
                    name="weapons"
                    color="primary"
                    size="small"
                    sx={{ margin: "5px" }}
                    onClick={handleRemoveWeaponSystem}
                  >
                    <Remove />
                  </Fab>
                </div>
                <Typography>Cost: {getCost("weapons")}</Typography>
              </Subsystem>
            </Stack>
          </CardContent>
        </Card>
        {renderWeaponSystems()}
        <Button
          variant="contained"
          onClick={submitShip}
          sx={{ marginRight: "10px" }}
        >
          Submit
        </Button>
        <Button
          variant="contained"
          component="label"
          sx={{ marginLeft: "10px" }}
        >
          Load Ship
          <input type="file" onChange={onFileChange} hidden />
        </Button>
      </div>
    </Paper>
  );
}

export default Builder;
