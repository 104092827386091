import { Button, Card, CardActions, CardContent, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import CanvasJSReact from "../assets/canvasjs.react";
import HealthControls from "../HealthControls/HealthControls";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

function HealthTracker(props) {
  const [chartData, setChartData] = useState([]);
  const [points, setPoints] = useState([]);
  const [activeSystems, setActiveSystems] = useState([
    {
      baseHull: true,
      bridge: true,
      energyStorage: true,
      engines: false,
      shieldGenerators: false,
      hangers: false,
      weapons: false,
    },
  ]);
  const [minHp, setMinHp] = useState([]);
  const [maxHp, setMaxHp] = useState([]);
  const [reset, setReset] = useState(false);

  useEffect(() => {
    let baseHp = 0;
    let interval = 0;
    if (props.shipCategory === 1) {
      baseHp = 10;
      interval = 10;
    } else if (props.shipCategory === 2) {
      baseHp = 20;
      interval = 20;
    } else if (props.shipCategory === 3) {
      baseHp = 40;
      interval = 40;
    }

    let baseHull = 0;
    if (props.shipType === "fighter") {
      baseHull = 100 + 10 * props.counts.hulls;
    }
    if (props.shipType === "gunship") {
      baseHull = 200 + 10 * props.counts.hulls;
    }
    if (props.shipType === "corvette") {
      baseHull = 400 + 10 * props.counts.hulls;
    }
    if (props.shipType === "frigate") {
      baseHull = 800 + 10 * props.counts.hulls;
    }
    if (props.shipType === "cruiser") {
      baseHull = 1600 + 10 * props.counts.hulls;
    }
    if (props.shipType === "battleship") {
      baseHull = 3200 + 10 * props.counts.hulls;
    }

    const constantLabels = [
      { label: "Base Hull", y: baseHull },
      { label: "Bridge", y: baseHp * 5 + 10 * props.counts.hulls },
      { label: "Energy Storage", y: baseHp * 2 + 10 * props.counts.hulls },
    ];

    function getPoints() {
      let points = [];
      if (props.counts.engines > 0) {
        points = points.concat([
          { label: "Engines", y: baseHp + 10 * props.counts.hulls },
        ]);
        setActiveSystems((activeSystems) => ({
          ...activeSystems,
          engines: true,
        }));
      }
      if (props.counts.shields > 0) {
        points = points.concat([
          { label: "Shield Generators", y: baseHp + 10 * props.counts.hulls },
        ]);
        setActiveSystems((activeSystems) => ({
          ...activeSystems,
          shieldGenerators: true,
        }));
      }
      if (props.counts.hangers > 0) {
        points = points.concat([
          { label: "Hangers", y: baseHp + 10 * props.counts.hulls },
        ]);
        setActiveSystems((activeSystems) => ({
          ...activeSystems,
          hangers: true,
        }));
      }
      if (props.counts.weapons > 0) {
        for (var i = 0; i < props.counts.weapons; i++) {
          points = points.concat([
            {
              label: "Weapon System " + (i + 1),
              y: baseHp + 10 * props.counts.hulls,
            },
          ]);
        }
        setActiveSystems((activeSystems) => ({
          ...activeSystems,
          weapons: true,
        }));
      }
      return points;
    }

    setMinHp({
      baseHull: 0,
      bridge: -(baseHp * 5 + 10 * props.counts.hulls),
      energyStorage: -(baseHp * 2 + 10 * props.counts.hulls),
      engines: -(baseHp + 10 * props.counts.hulls),
      shieldGenerators: -(baseHp + 10 * props.counts.hulls),
      hangers: -(baseHp + 10 * props.counts.hulls),
      weapons: -(baseHp + 10 * props.counts.hulls),
    });

    setMaxHp({
      baseHull: baseHull,
      bridge: baseHp * 5 + 10 * props.counts.hulls,
      energyStorage: baseHp * 2 + 10 * props.counts.hulls,
      engines: baseHp + 10 * props.counts.hulls,
      shieldGenerators: baseHp + 10 * props.counts.hulls,
      hangers: baseHp + 10 * props.counts.hulls,
      weapons: baseHp + 10 * props.counts.hulls,
    });

    let points = constantLabels.concat(getPoints());

    const data = {
      animationEnabled: true,
      title: {
        text: "System Hp",
      },
      data: [
        {
          type: "column",
          dataPoints: points,
        },
      ],
      axisY: {
        minimum: 0 - (baseHp * 5 + 10 * props.counts.hulls),
        maximum: (baseHp * 5 + 10 * props.counts.hulls) * 2,
        interval: interval,
      },
    };

    setChartData(data);
    setPoints(points);
  }, [props.counts, props.shipCategory, props.shipType, reset]);

  useEffect(() => {
    setChartData((chartData) => ({
      ...chartData,
      data: [
        {
          type: "column",
          dataPoints: points,
        },
      ],
    }));
  }, [points]);

  function controlCallback(newSystem, aoeDamage, damage, repair) {
    if (newSystem === "Base Hull" && aoeDamage > 0) {
      setPoints(
        points.map((point) => {
          let newY = point.y - aoeDamage;

          return {
            label: point.label,
            y: newY,
          };
        })
      );
    }
    if (damage > 0) {
      setPoints(
        points.map((point) => {
          let newY = getHp(point.label);
          if (point.label === newSystem) {
            newY = point.y - damage;
          }

          return {
            label: point.label,
            y: newY,
          };
        })
      );
    }
    if (repair > 0 && getHp(newSystem) > getMinHp(newSystem)) {
      setPoints(
        points.map((point) => {
          let newY = getHp(point.label);
          if (point.label === newSystem) {
            newY = parseInt(point.y) + parseInt(repair);
          }
          if (newY > getMaxHp(newSystem)) {
            newY = parseInt(getMaxHp(point.label));
          }

          return {
            label: point.label,
            y: newY,
          };
        })
      );
    }
  }

  function handleReset() {
    setReset(!reset);
  }

  function getMinHp(system) {
    switch (system) {
      case "Base Hull":
        return minHp.baseHull;
      case "Bridge":
        return minHp.bridge;
      case "Energy Storage":
        return minHp.energyStorage;
      case "Engines":
        return minHp.engines;
      case "Shield Generators":
        return minHp.shieldGenerators;
      case "Hangers":
        return minHp.hangers;
      default:
        return minHp.weapons;
    }
  }

  function getMaxHp(system) {
    switch (system) {
      case "Base Hull":
        return maxHp.baseHull;
      case "Bridge":
        return maxHp.bridge;
      case "Energy Storage":
        return maxHp.energyStorage;
      case "Engines":
        return maxHp.engines;
      case "Shield Generators":
        return maxHp.shieldGenerators;
      case "Hangers":
        return maxHp.hangers;
      default:
        return maxHp.weapons;
    }
  }

  function getHp(system) {
    if (points.length > 0) {
      return points.filter((point) => point.label === system).pop().y;
    } else {
      return 0;
    }
  }

  function renderWeaponSystems() {
    let i = -1;

    return points
      .filter((point) => point.label.includes("Weapon System"))
      .map((point) => {
        ++i;
        return (
          <HealthControls
            key={i}
            currentHp={getHp(point.label)}
            maxHp={maxHp.weapons}
            system={point.label}
            callback={controlCallback}
          />
        );
      });
  }

  return (
    <Card
      sx={{
        marginTop: "1%",
        width: "80%",
        height: "fit-content",
        minHeight: "40%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "1%",
      }}
    >
      <CardContent
        sx={{
          width: "95%",
          height: "fit-content",
          minHeight: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <CanvasJSChart options={chartData} />
        <Stack
          direction="row"
          sx={{ flexWrap: "wrap", marginTop: "10px", justifyContent: "center" }}
        >
          <HealthControls
            currentHp={getHp("Base Hull")}
            maxHp={maxHp.baseHull}
            system="Base Hull"
            callback={controlCallback}
          />
          <HealthControls
            currentHp={getHp("Bridge")}
            maxHp={maxHp.bridge}
            system="Bridge"
            callback={controlCallback}
          />
          <HealthControls
            currentHp={getHp("Energy Storage")}
            maxHp={maxHp.energyStorage}
            system="Energy Storage"
            callback={controlCallback}
          />
          {activeSystems.engines && (
            <HealthControls
              currentHp={getHp("Engines")}
              maxHp={maxHp.engines}
              system="Engines"
              callback={controlCallback}
            />
          )}
          {activeSystems.shieldGenerators && (
            <HealthControls
              currentHp={getHp("Shield Generators")}
              maxHp={maxHp.shieldGenerators}
              system="Shield Generators"
              callback={controlCallback}
            />
          )}
          {activeSystems.hangers && (
            <HealthControls
              currentHp={getHp("Hangers")}
              maxHp={maxHp.hangers}
              system="Hangers"
              callback={controlCallback}
            />
          )}
          {activeSystems.weapons && renderWeaponSystems()}
        </Stack>
      </CardContent>
      <CardActions>
        <Button variant="contained" size="large" onClick={handleReset}>
          Reset
        </Button>
      </CardActions>
    </Card>
  );
}

export default HealthTracker;
