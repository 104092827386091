import {
  Card,
  CardContent,
  CardHeader,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import Weapon from "../Weapon/Weapon";
import validator from "validator";

function WeaponCalculators(props) {
  const [range, setRange] = useState(0);
  const [error, setError] = useState(false);
  const [errorText, setErrorText] = useState("");

  function renderWeapons() {
    return props.weaponSystems.map((weapon) => {
      return <Weapon key={weapon.key} weaponSystem={weapon} range={range} />;
    });
  }

  function handleRange(event) {
    if (event.currentTarget.value !== null) {
      if (
        validator.isInt(event.currentTarget.value) ||
        event.currentTarget.value === ""
      ) {
        setRange(event.currentTarget.value);
        setError(false);
        setErrorText("");
      } else {
        setError(true);
        setErrorText("Must be an integer");
      }
    }
  }

  return (
    <Card
      sx={{
        marginTop: "2.5%",
        width: "80%",
        height: "fit-content",
        minHeight: "40%",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: "5%",
      }}
    >
      <CardHeader title="Weapon Calculator" />
      <CardContent
        sx={{
          width: "95%",
          height: "fit-content",
          minHeight: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography>Range:</Typography>
        <TextField
          label="Range (in spaces)"
          variant="outlined"
          value={range || ""}
          onChange={handleRange}
          error={error}
          helperText={errorText}
        />
        <Stack
          direction="row"
          sx={{
            flexWrap: "wrap",
            marginTop: "10px",
            justifyContent: "center",
            width: "90%",
          }}
        >
          {renderWeapons()}
        </Stack>
      </CardContent>
    </Card>
  );
}

export default WeaponCalculators;
