import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";

function Weapon(props) {
  const [weapons, setWeapons] = useState([]);
  const [result, setResult] = useState();

  useEffect(() => {
    setWeapons([]);
    if (props.weaponSystem.autoCannon > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Auto Cannon",
          amount: props.weaponSystem.autoCannon,
        })
      );
    }
    if (props.weaponSystem.ionDisabler > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Ion Disabler",
          amount: props.weaponSystem.ionDisabler,
        })
      );
    }
    if (props.weaponSystem.hullLaser > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Hull Laser",
          amount: props.weaponSystem.hullLaser,
        })
      );
    }
    if (props.weaponSystem.flakCannon > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Flak Cannon",
          amount: props.weaponSystem.flakCannon,
        })
      );
    }
    if (props.weaponSystem.mineLayer > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Mine Layer",
          amount: props.weaponSystem.mineLayer,
        })
      );
    }
    if (props.weaponSystem.energyCannon > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Energy Cannon",
          amount: props.weaponSystem.energyCannon,
        })
      );
    }
    if (props.weaponSystem.torpedoLauncher > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Torpedo Launcher",
          amount: props.weaponSystem.torpedoLauncher,
        })
      );
    }
    if (props.weaponSystem.interstellarArtillery > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Interstellar Artillery",
          amount: props.weaponSystem.interstellarArtillery,
        })
      );
    }
    if (props.weaponSystem.railgun) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Railgun",
          amount: props.weaponSystem.railgun,
        })
      );
    }
    if (props.weaponSystem.gravitationalVice > 0) {
      setWeapons((weapons) =>
        weapons.concat({
          name: "Gravitational Vice",
          amount: props.weaponSystem.gravitationalVice,
        })
      );
    }
  }, [props]);

  useEffect(() => {}, [result]);

  function getWeaponsOnboard() {
    return weapons.map((weapon) => {
      return (
        <Typography>
          {weapon.name}: {weapon.amount}
        </Typography>
      );
    });
  }

  function getDamageOutput() {
    let damage = "";
    weapons.forEach((weapon) => {
      if (
        weapon.name !== "Mine Layer" &&
        weapon.name !== "Torpedo Launcher" &&
        weapon.name !== "Gravitational Vice"
      ) {
        damage = damage + getDamage(weapon, props.range) + " + ";
      }
    });
    damage = damage.replace(/\s$/g, "").replace(/[+]$/g, "");
    return <Typography>{damage}</Typography>;
  }

  function getDamage(weapon, range) {
    if (weapon.name === "Auto Cannon") {
      return weapon.amount + "d6";
    } else if (weapon.name === "Ion Disabler" && range <= 10) {
      return weapon.amount + "d6";
    } else if (weapon.name === "Hull Laser") {
      return weapon.amount + "d6";
    } else if (weapon.name === "Flak Cannon") {
      return range <= 5 ? weapon.amount * 4 + "d4" : weapon.amount + "d4";
    } else if (weapon.name === "Energy Cannon") {
      return weapon.amount + "d8";
    } else if (weapon.name === "Interstellar Artillery" && range > 500) {
      return "10d10";
    } else if (weapon.name === "Railgun") {
      return range >= 40
        ? weapon.amount * Math.min(Math.trunc((range - 20) / 10), 4) + "d8"
        : weapon.amount + "d8";
    } else {
      return "";
    }
  }

  function rollDamage(dice) {
    let numToRoll = "";
    let sides = "";
    let delimiter = dice.indexOf("d");

    if (delimiter > -1) {
      for (let i = 0; i < delimiter; i++) {
        numToRoll = numToRoll + dice.at(i);
      }
      for (let i = delimiter + 1; i < dice.length; i++) {
        sides = sides + dice.at(i);
      }
    }
    let amountToRoll = parseInt(numToRoll);
    let sidesNum = parseInt(sides);
    let total = 0;
    for (let i = 0; i < amountToRoll; i++) {
      total = total + Math.floor(Math.random() * sidesNum);
    }
    return total;
  }

  function handleFire() {
    let damage = 0;
    weapons.forEach((weapon) => {
      damage = damage + rollDamage(getDamage(weapon, props.range));
    });
    setResult(damage);
  }

  function getAdditionalEffects() {
    return weapons.map((weapon) => {
      if (weapon.name === "Auto Cannon") {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; When a hostile ship comes withing 30 spaces, you may use
            your ship reaction to fire {weapon.amount} auto cannons, dealing{" "}
            {weapon.amount}d6 damage on hit.
          </Typography>
        );
      } else if (weapon.name === "Ion Disabler") {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; Roll 6d8. If the targeted subsystem hp &gt;= that roll then
            it ceases functioning until the start of your next turn. This cannot
            be used again until the end of your next turn.
          </Typography>
        );
      } else if (weapon.name === "Hull Laser") {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; Deal damage to target base hull = 5% of it's max hp.
          </Typography>
        );
      } else if (weapon.name === "Flak Cannon" && props.range <= 5) {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; +3 to hit.
          </Typography>
        );
      } else if (weapon.name === "Mine Layer") {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; Place a mine with a radius of 2 spaces. If a ship enters the
            radius it must make a dc 13 evasion save or take 2d6 damage to all
            subsystems and base hull. It takes half that damage if it succeeds.
          </Typography>
        );
      } else if (weapon.name === "Torpedo Launcher") {
        if (props.range >= 10) {
          return (
            <Typography
              sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
            >
              &#8226; A single target ship must make a dc 15 evasion save or
              take 2d6 damage to one subsystem. It takes no damage if it
              succeeds.
            </Typography>
          );
        } else {
          return (
            <Typography
              sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
            >
              &#8226; A single target ship must make a dc 15 evasion save or
              take 2d8 damage to all subsystems and basehull. It takes half that
              damage if it succeeds.
            </Typography>
          );
        }
      } else if (weapon.name === "Gravitational Vice") {
        return (
          <Typography
            sx={{ textAlign: "left", marginBottom: "15px", width: "80%" }}
          >
            &#8226; The target must make a dc 15 evasion save or their movement
            speed becomes 0. If they succeed their first movement action is at
            half speed. They may reattempt saving throws at the end of each of
            their turns to end the effect. This effect ends if the attacker ever
            moves 30 spaces of more away. If a target already under this effect
            is attacked by a gravitational vice, it instead takes 1d6 damage for
            each turn it has been effected.
          </Typography>
        );
      } else {
        return "";
      }
    });
  }

  return (
    <Card
      elevation={6}
      sx={{
        marginBottom: "30px",
        marginRight: "2%",
        width: "300px",
        sm: { width: "90%" },
      }}
    >
      <CardHeader title={"Weapon System " + (props.weaponSystem.key + 1)} />
      <CardContent
        sx={{
          height: "fit-content",
          minHeight: "40%",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography
          variant="h6"
          sx={{ textDecoration: "underline" }}
          display="inline"
        >
          Weapons Onboard
        </Typography>
        {getWeaponsOnboard()}
        <Typography
          variant="h6"
          sx={{ marginTop: "10px", textDecoration: "underline" }}
        >
          Damage Output
        </Typography>
        {getDamageOutput()}
        <Typography
          variant="h6"
          sx={{ marginTop: "10px", textDecoration: "underline" }}
        >
          Additional Effects
        </Typography>
        {getAdditionalEffects()}
        <Button
          variant="contained"
          onClick={handleFire}
          sx={{ marginTop: "10px", textDecoration: "underline" }}
        >
          Fire
        </Button>
        <Typography
          variant="h6"
          sx={{ marginTop: "30px", textDecoration: "underline" }}
        >
          Result
        </Typography>
        <Typography>{result}</Typography>
      </CardContent>
    </Card>
  );
}
export default Weapon;
