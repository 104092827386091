import { createTheme, responsiveFontSizes, ThemeProvider } from "@mui/material";
import { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Builder from "./Builder/Builder";
import Sheet from "./Sheet/Sheet";

let shipBuilderTheme = createTheme({
  palette: {
    type: "dark",
    primary: {
      main: "#3946bf",
      dark: "#353535",
      contrastText: "#ffffff",
    },
    secondary: {
      main: "#4786d4",
      contrastText: "#f7f7f7",
    },
    background: {
      default: "#000000",
      paper: "#ffffff",
    },
    text: {
      primary: "#2F2F2F",
      secondary: "#8787872",
      disabled: "rgba(226,225,225,0.37)",
      hint: "rgba(210,206,206,0.5)",
    },
  },
});

responsiveFontSizes(shipBuilderTheme);

function App() {
  const [ship, setShip] = useState([]);
  const [counts, setCounts] = useState([]);
  const [weaponSystems, setWeaponSystems] = useState([]);

  function setData(newShip, newCounts, newWeaponSystems) {
    setShip(newShip);
    setCounts(newCounts);
    setWeaponSystems(newWeaponSystems);
  }

  useEffect(() => {}, [ship, counts, weaponSystems]);

  return (
    <ThemeProvider theme={shipBuilderTheme}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Builder callback={setData} />} />
            <Route
              path="/sheet"
              element={
                <Sheet
                  ship={ship}
                  counts={counts}
                  weaponSystems={weaponSystems}
                />
              }
            />
          </Routes>
        </BrowserRouter>
      </div>
    </ThemeProvider>
  );
}

export default App;
